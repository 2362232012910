import {
  getNavigationContainer,
  getNavigationWrapper,
  getCurrentIsMouseInDropdown,
  getCurrentIsMouseInLink,
  getCurrentLink,
  getDropdownElements,
  getStaticLinkElements,
  hideNavDropdown,
  hideOldLinkContent,
  setCurrentIsMouseInDropdown,
  setCurrentIsMouseInLink,
  showNavDropdown,
} from '../desktopDropdown'
import { getMenuElements } from '../mobileMenu'
import {
  makeInvisible,
  makeVisible,
  setDisplayFlex,
  setDisplayNone,
  isVisible,
} from '../utils'

const addDropdownMouseleaveListeners = () => {
  const { dropdown } = getDropdownElements()

  if (dropdown) {
    dropdown.addEventListener('mouseleave', () => {
      window.setTimeout(hideNavDropdown, 10)
    })
    dropdown.addEventListener('mouseover', () =>
      setCurrentIsMouseInDropdown(true)
    )
    dropdown.addEventListener('mouseleave', () =>
      setCurrentIsMouseInDropdown(false)
    )
  }
}

const addStaticLinkMouseleaveListeners = () => {
  const staticLinks = getStaticLinkElements()
  Object.values(staticLinks).forEach((link) => {
    link &&
      link.addEventListener('mouseleave', () => {
        link.classList.remove('open')
      })
  })
}

const addDropdownMouseoverListeners = () => {
  const { bottomRowLinks } = getDropdownElements()

  bottomRowLinks.forEach((link) => {
    const linkContent = document.querySelector('#link-' + link.id)

    if (link) {
      let delayOpening = null

      link.addEventListener('mouseover', () => {
        const delayDuration = getCurrentLink() ? 0 : 400
        setCurrentIsMouseInLink(true)
        if (getCurrentLink() && (!linkContent || !isVisible(linkContent))) {
          hideOldLinkContent()
        }
        link.classList.add('open')

        delayOpening = window.setTimeout(function () {
          if (linkContent && linkContent.innerHTML) {
            showNavDropdown(link, linkContent)
          } else {
            hideNavDropdown()
          }
        }, delayDuration)
      })

      link.addEventListener('mouseleave', () => {
        clearTimeout(delayOpening)
        if (linkContent && !isVisible(linkContent)) {
          link.classList.remove('open')
        }
        setCurrentIsMouseInLink(false)
        setTimeout(() => {
          if (!getCurrentIsMouseInDropdown() && !getCurrentIsMouseInLink()) {
            hideNavDropdown()
          }
        }, 400)
      })
    }
  })
}

const desktopScrollEvent = (sticky) => {
  const { navigationContainer: desktopNav } = getNavigationContainer()
  const { navigationWrapper } = getNavigationWrapper()
  if (isVisible(desktopNav)) {
    const stickyLinksLeft = document.querySelector('.bottom-row__links-left')
    const stickyLinksRight = document.querySelector('.bottom-row__links-right')
    const stickyLogoSmallDesktop = document.querySelector('.bottom-row__logo')
    if (
      window.pageYOffset > sticky &&
      !desktopNav.className.includes('sticky')
    ) {
      navigationWrapper.style.minHeight = `${desktopNav.offsetHeight}px`
      desktopNav.classList.add('sticky')
      stickyLogoSmallDesktop.classList.add('bottom-row__logo__visible')
      makeVisible(stickyLinksLeft)
      makeVisible(stickyLinksRight)
    }
    if (
      window.pageYOffset <= sticky &&
      desktopNav.className.includes('sticky')
    ) {
      navigationWrapper.style.minHeight = 'auto'
      desktopNav.classList.remove('sticky')
      document.body.style.marginTop = 'initial'
      stickyLogoSmallDesktop.classList.remove('bottom-row__logo__visible')
      makeInvisible(stickyLinksLeft)
      makeInvisible(stickyLinksRight)
    }
  }
}

const mobileScrollEvent = (sticky) => {
  const { navigationContainer: mobileNav, navigationWrapper } =
    getMenuElements()
  if (isVisible(mobileNav)) {
    if (window.pageYOffset > sticky) {
      navigationWrapper.style.minHeight = `${navigationWrapper.offsetHeight}px`
      mobileNav.classList.add('sticky')
    } else {
      navigationWrapper.style.minHeight = 'auto'
      mobileNav.classList.remove('sticky')
      document.body.style.marginTop = 'initial'
    }
  }
}

const getCurrentStickyScrollPosition = (
  initialDesktopSticky = 0,
  initialMobileSticky = 0
) => {
  const { navigationContainer: desktopNav } = getNavigationContainer()
  const navigationLinks = desktopNav.querySelector(
    '.navigation__links-dropdown'
  )
  const desktopSticky = navigationLinks.offsetTop - window.pageYOffset
  const { navigationContainer: mobileNav } = getMenuElements()
  const mobileSticky = mobileNav.offsetTop - window.pageYOffset
  return {
    desktopSticky:
      desktopSticky > initialDesktopSticky
        ? desktopSticky
        : initialDesktopSticky,
    mobileSticky:
      initialMobileSticky <= 0 || initialMobileSticky > 300
        ? mobileSticky
        : initialMobileSticky,
  }
}

const addScrollListener = () => {
  let stickies = getCurrentStickyScrollPosition() || {}
  window.addEventListener('scroll', () => {
    stickies = getCurrentStickyScrollPosition(
      stickies.desktopSticky,
      stickies.mobileSticky
    )
    // desktopScrollEvent(stickies.desktopSticky)
    mobileScrollEvent(stickies.mobileSticky)
  })
}

const positioningAccountMenuDropdown = () => {
  const myAccountMenu = document.querySelector(
    '.top-row__links--my-account__menu'
  )
  const myAccountMenuButton = document.getElementById(
    'nav-link__desktop--my-account'
  )
  const myAccountMenuIcon = document.querySelector('.my-account--logged-in')
  let topOffset = -20
  let accountButtonPosition = myAccountMenuButton.getBoundingClientRect()
  if (isVisible(myAccountMenuIcon)) {
    accountButtonPosition = myAccountMenuIcon.getBoundingClientRect()
    topOffset = 105
  }
  myAccountMenu.style.top = accountButtonPosition.top + topOffset + 'px'
}

const addMouseEnterLeaveEvents = (element, updateCount) => {
  element.addEventListener('mouseenter', () => {
    setTimeout(() => {
      updateCount(1)
    }, 200)
  })
  element.addEventListener('mouseleave', () => {
    setTimeout(() => {
      updateCount(-1)
    }, 400)
  })
}

const addAccountListener = () => {
  const myAccountMenu = document.querySelector(
    '.top-row__links--my-account__menu'
  )
  const myAccountMenuButton = document.getElementById(
    'nav-link__desktop--my-account'
  )
  const myAccountMenuIcon = document.querySelector('.my-account--logged-in')
  let count = 0
  const updateCount = (number) => {
    count = count + number
    //only want to hide menu if both are not hovered over
    if (count > 0) {
      positioningAccountMenuDropdown()
      setDisplayFlex(myAccountMenu)
    } else {
      setDisplayNone(myAccountMenu)
    }
  }

  myAccountMenuButton &&
    addMouseEnterLeaveEvents(myAccountMenuButton, updateCount)
  myAccountMenuIcon && addMouseEnterLeaveEvents(myAccountMenuIcon, updateCount)
  myAccountMenu && addMouseEnterLeaveEvents(myAccountMenu, updateCount)
}

const resizeEvent = () => {
  const { navigationWrapper: desktopWrapper } = getNavigationWrapper()
  const { navigationWrapper: mobileWrapper } = getMenuElements()
  if (isVisible(desktopWrapper)) {
    desktopWrapper.style.minHeight = 'auto'
  } else {
    mobileWrapper.style.minHeight = 'auto'
  }
}

const addResizeEvent = () => {
  window.addEventListener('resize', resizeEvent)
}

export const addDesktopDropdownListeners = () => {
  addDropdownMouseoverListeners()
  addDropdownMouseleaveListeners()
  addStaticLinkMouseleaveListeners()
  addScrollListener()
  addAccountListener()
  addResizeEvent()
}
